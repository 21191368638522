import React from "react"
import Layout from "../../components/_App/layout"
import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Partner from "../../components/Common/Partner"
import Footer from "../../components/_App/Footer"
import { Link } from "gatsby"
import "../../assets/css/tools.css"

const toolsArr = [
  {
    slug: "youtube-thumbnail-downloader",
    nav: "youtube-thumbnail-downloader",
    title: "Youtube Thumbnail Downloader",
    description: "Download youtube thumbnails with One Click."
  },
  {
    slug: "loan-emi-calculator",
    nav: "loan-emi-calculator",
    title: "Loan EMI Calculator",
    description: "Calculate Loan EMI with one Click."
  },
  {
    slug: "gst-calculator",
    nav: "gst-calculator",
    title: "GST Calculator",
    description: "Calculate GST with One Click."
  },
  {
    slug: "replace-text-generator",
    nav: "replace-text-generator",
    title: "Replace Text Generator",
    description: "Replace Text Generator."
  },
  {
    slug: "csv-to-json",
    nav: "csv-to-json",
    title: "CSV to JSON",
    description: "Convert CSV file to JSON with one Click."
  },
  {
    slug: "binary-code-translator",
    nav: "binary-code-translator",
    title: "Binary Code Translator",
    description: " Binary text with One Click."
  },
  {
    slug: "bold-text-generator",
    nav: "bold-text-generator",
    title: "Bold Text Generator",
    description: "Bold text with One Click."
  },
  {
    slug: "convert-case",
    nav: "convert-case",
    title: "Convert Case",
    description: "Convert Case One Click."
  },
  {
    slug: "italic-text-generator",
    nav: "italic-text-generator",
    title: "Italic Text Generator",
    description: "Italic text with One Click."
  },
  {
    slug: "pascal-case-generator",
    nav: "pascal-case-generator",
    title: "Pascal Case Generator",
    description: "Pascal text with One Click."
  },
  {
    slug: "reverse-text-generator",
    nav: "reverse-text-generator",
    title: "Reverse text Generator",
    description: "Reverse text with One Click."
  },
  {
    slug: "Slugify-URL-Generator",
    nav: "Slugify-URL-Generator",
    title: "Slugify URL Generator",
    description: "Slugify URL with One Click."
  },
  {
    slug: "snake-case-generator",
    nav: "snake-case-generator",
    title: "Snake Case Generator",
    description: "Snake text with One Click."
  },
  {
    slug: "strikethrough-text-generator",
    nav: "strikethrough-text-generator",
    title: "Strike Through Text Generator",
    description: "Strike text with One Click."
  },
  {
    slug: "upside-down-text-generator",
    nav: "upside-down-text-generator",
    title: "Upside down text Generator",
    description: "Upside down text with One Click."
  },
  {
    slug: "wide-text-generator",
    nav: "wide-text-generator",
    title: "Wide Text Generator",
    description: "Wide text with One Click."
  },
  {
    slug: "color-pallets",
    nav: "color-pallets/203200-4129be-5dbe1b-75e73f-491240",
    title: "Color Palettes Generator",
    description: "Generate custom color pallets with One Click."
  },
  {
    slug: "qr-code",
    nav: "qr-code",
    title: "QR Code Generator",
    description: "Generate QR Code."
  },
  {
    slug: "json-parser",
    nav: "json-parser",
    title: "JSON Parser",
    description: "JSON Parser."
  },
  {
    slug: "gradient-backgrounds",
    nav: "gradient-backgrounds/90-7e3120-ba8cda",
    title: "Gradient Background Generator",
    description: "Gradient Background Generator."
  }, {
    slug: "json-to-csv",
    nav: "json-to-csv",
    title: "JSON to CSV",
    description: "JSON to CSV Generator."
  },
  {
    slug: "webp-to-jpg",
    nav: "webp-to-jpg",
    title: "WebP to JPG",
    description: "Convert WebP image to JPG with one Click."
  }
  , {
    slug: "webp-to-png",
    nav: "webp-to-png",
    title: "WebP to PNG",
    description: "Convert WebP image to PNG with one Click."
  },
  {
    slug: "jpg-to-webp",
    nav: "jpg-to-webp",
    title: "JPG to WebP",
    description: "Convert JPG image to WebP with one Click."
  }, {
    slug: "png-to-webp",
    nav: "png-to-webp",
    title: "PNG to WebP",
    description: "Convert PNG image to WebP with one Click."
  },
  {
    slug: "repeat-text",
    nav: "repeat-text",
    title: "Repeat Text Generator",
    description: "Instantly Repeat Text with One Click."
  },
  {
    slug: "javascript-to-typescript",
    nav: "javascript-to-typescript",
    title: "Javascript to Typescript",
    description: "Convert JS Object to TS Interface with one Click."
  }
]
const Tools = () => (
  <Layout>
    <Seo title="Free Tools" />

    <Navbar />

    {/*<PageBanner pageTitle="Tools" />*/}

    <div className="tools-area pt-120-b-80">
      <div className="container">
        <div className="section-title">
          {/*<span className="sub-title">Our Solutions</span>*/}
          <h1>Free Tools</h1>
          <div className="bar" />
        </div>
        <div className="tools-inner-area">
          <div className="row justify-content-center">
            {
              toolsArr.map(tool => {
                return (<div className="col-lg-4 p-0">
                  <Link
                    to={"/tools/" + tool.nav}
                    // onClick={toggleNavbar}
                    className="nav-link px-2"
                  >
                    <div className="tools-tile single-box bg-eb6b3d">
                      <h2>{tool.title}</h2>
                      <p>{tool.description}</p>
                    </div>
                  </Link>
                </div>)
              })
            }
          </div>
        </div>
      </div>
    </div>

    <Partner />

    <MilestonesAchievedSoFar />

    <Footer />
  </Layout>
)

export default Tools
